<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto d-lg-none" to="/">
        <!-- <CIcon :icon="logo" height="48" alt="Logo" /> -->
        <!-- <img
          src="https://scontent.fcgk18-2.fna.fbcdn.net/v/t39.30808-6/310481612_674314544053348_3780922753962816198_n.png?_nc_cat=1&ccb=1-7&_nc_sid=09cbfe&_nc_eui2=AeE0WB8f2YSAP2Hu_M9VbZCQsNdU92_407iw11T3b_jTuGFKckm9lkBA7bptgqEzZsSD2OxiZEDiFuFVH0rJf5zU&_nc_ohc=W3NgzcO-cOIAX-mQMWb&_nc_ht=scontent.fcgk18-2.fna&oh=00_AfA4GEgSjC6c8mmZ_fXwJhDQrS4-BH37W3hcYO9iqBXVIw&oe=64165494"
          alt="loadiing"
          style="height: 50px; width: 50px"
        /> -->
        <h4 class="text-dark">&nbsp; 大马搵食Kaki</h4>
      </CHeaderBrand>
      <CHeaderNav class="d-none d-md-flex me-auto"> </CHeaderNav>
      <CHeaderNav>
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
    <CHeaderDivider />
    <CContainer fluid>
      <AppBreadcrumb />
    </CContainer>
  </CHeader>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import { logo } from '@/assets/brand/logo'
export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
    AppHeaderDropdownAccnt,
  },
  setup() {
    return {
      logo,
    }
  },
}
</script>
