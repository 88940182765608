import { h, resolveComponent } from 'vue'
import {
  createRouter,
  // createWebHashHistory,
  createWebHistory,
} from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'
import LayoutFullVideo from '@/layouts/LayoutFullVideo'

const routes = [
  {
    path: '/',
    redirect: '/dash2',
    component: LayoutFullVideo,
    children: [
      {
        path: '/fullvideo',
        name: 'FullVideo',
        component: () => import('@/views/fullVideo.vue'),
      },
    ],
  },
  {
    path: '/',
    name: 'Dashboard',
    component: DefaultLayout,
    redirect: '/dash2',
    children: [
      // {
      //   path: '/fullvideo',
      //   name: 'FullVideo',
      //   component: () => import('@/views/fullVideo.vue'),
      // },
      {
        path: '/fullvideo2',
        name: 'FullVideo2',
        component: () => import('@/views/fullVideo2.vue'),
      },
      {
        path: '/fullvideo3',
        name: 'FullVideo3',
        component: () => import('@/views/fullVideo3.vue'),
      },
      {
        path: '/setsurveyform',
        name: 'Setting Form',
        component: () => import('@/views/pages/setSurvey.vue'),
      },
      ////--------------------------------------------------------////////////////////////////////
      {
        path: '/survey',
        name: 'Survey',
        component: () => import('@/views/pages/survey.vue'),
      },
      {
        path: '/dashboardme',
        name: 'Dashboard',
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboardme.vue'),
      },
      {
        path: '/delv',
        name: 'Delvoucher',
        component: () => import('@/views/DelVoucher.vue'),
      },
      {
        path: '/delb',
        name: 'Delbanner',
        component: () => import('@/views/DelBanner.vue'),
      },
      {
        path: '/delvi',
        name: 'Delvideo',
        component: () => import('@/views/DelVideo.vue'),
      },
      {
        path: '/scan',
        name: 'Scanvoucher',
        component: () => import('@/views/ScanVoucher.vue'),
      },
      {
        path: '/addvoucher',
        name: 'Add Voucher',
        component: () => import('@/views/forms/FormAddVoucher.vue'),
      },
      {
        path: '/addbanner',
        name: 'Add Banner',
        component: () => import('@/views/forms/FormAddBanner.vue'),
      },
      {
        path: '/addvideo',
        name: 'Add Video',
        component: () => import('@/views/forms/FormAddVideo.vue'),
      },
      {
        path: '/editvideo',
        name: 'Edit Video',
        component: () => import('@/views/forms/FormEditVideo.vue'),
      },
      {
        path: '/addvideoyt',
        name: 'Add Youtube Video',
        component: () => import('@/views/forms/FormAddVideoYT.vue'),
      },
      {
        path: '/listvouchersadmin',
        name: 'List Vouchers',
        component: () => import('@/views/ListVouchersAdmin.vue'),
      },
      {
        path: '/listbannersadmin',
        name: 'List Banners',
        component: () => import('@/views/ListBannersAdmin.vue'),
      },
      {
        path: '/listvideosadmin',
        name: 'List Videos',
        component: () => import('@/views/ListVideosAdmin.vue'),
      },
      {
        path: '/listmerchant',
        name: 'List merchants',
        component: () => import('@/views/ListMerchant.vue'),
      },
      {
        path: '/listmember',
        name: 'Members Report',
        component: () => import('@/views/ListMember.vue'),
      },
      {
        path: '/reportVouchers',
        name: 'Vouchers Report',
        component: () => import('@/views/ReportVouchersAdmin.vue'),
      },
      {
        path: '/loginu',
        name: 'Loginuser',
        component: () => import('@/views/LoginUser.vue'),
      },
      {
        path: '/logintoclaim',
        name: 'Logintoclaim',
        component: () => import('@/views/LoginToClaim.vue'),
      },
      {
        path: '/logintolist',
        name: 'Logintolist',
        component: () => import('@/views/LoginToListVouchers.vue'),
      },
      {
        path: '/loginnew',
        name: 'Loginnew',
        component: () => import('@/views/pages/Login.vue'),
      },
      {
        path: '/loginnew2',
        name: 'Loginnew2',
        component: () => import('@/views/pages/Login2.vue'),
      },
      {
        path: '/redirectgoogle',
        name: 'Redirect Google',
        component: () => import('@/views/pages/LoginRedirectGoogle.vue'),
      },
      {
        path: '/loginadmin',
        name: 'Login Admin',
        component: () => import('@/views/pages/LoginAdmin.vue'),
      },
      {
        path: '/registermerchant',
        name: 'Add Merchant',
        component: () => import('@/views/RegisterMerchant.vue'),
      },
      {
        path: '/registeruser',
        name: 'Registerr',
        component: () => import('@/views/RegisterUser.vue'),
      },
      {
        path: '/registeradminmyeatkaki',
        name: 'Register Admin',
        component: () => import('@/views/RegisterAdmin.vue'),
      },
      {
        path: '/dash2',
        name: 'dash2',
        component: () => import('@/views/dashBaru.vue'),
      },
      {
        path: '/homeuser',
        name: 'Homeuser',
        component: () => import('@/views/HomeUser.vue'),
      },
      {
        path: '/listvideosu',
        name: 'listVideosU',
        component: () => import('@/views/ListVideoUser.vue'),
      },
      {
        path: '/listvouchersu',
        name: 'listVouchersU',
        component: () => import('@/views/ListVoucherUser.vue'),
      },
      {
        path: '/listvouchersbaru',
        name: 'listVouchersNew',
        component: () => import('@/views/ListVoucherUserBaru.vue'),
      },
      {
        path: '/dvoucheru',
        name: 'detailVoucherUser',
        component: () => import('@/views/DetailVoucherUser.vue'),
      },
      {
        path: '/dhistory',
        name: 'detailHistoryUser',
        component: () => import('@/views/DetailHistoryUser.vue'),
      },
      {
        path: '/myvouchers',
        name: 'myVouchers',
        component: () => import('@/views/MyVouchers.vue'),
      },
      {
        path: '/myvouchersbaru',
        name: 'myVouchersBaru',
        component: () => import('@/views/MyVouchersBaru.vue'),
      },
      {
        path: '/dmyvoucher',
        name: 'DetailMyVoucher',
        component: () => import('@/views/DetailMyVoucher.vue'),
      },
      {
        path: '/myfavorites',
        name: 'MyFavorites',
        component: () => import('@/views/MyFavorites.vue'),
      },
      {
        path: '/myaccount',
        name: 'myAccount',
        component: () => import('@/views/myAccount.vue'),
      },
      {
        path: '/myprofile',
        name: 'myProfile',
        component: () => import('@/views/myProfile.vue'),
      },
      {
        path: '/history',
        name: 'history',
        component: () => import('@/views/historyUser.vue'),
      },
      {
        path: '/logout',
        name: 'logout',
        component: () => import('@/views/Logout.vue'),
      },
      {
        path: '/theme',
        name: 'Theme',
        redirect: '/theme/typography',
      },
      {
        path: '/theme/colors',
        name: 'Colors',
        component: () => import('@/views/theme/Colors.vue'),
      },
      {
        path: '/theme/typography',
        name: 'Typography',
        component: () => import('@/views/theme/Typography.vue'),
      },
      {
        path: '/base',
        name: 'Base',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/base/breadcrumbs',
        children: [
          {
            path: '/base/accordion',
            name: 'Accordion',
            component: () => import('@/views/base/Accordion.vue'),
          },
          {
            path: '/base/breadcrumbs',
            name: 'Breadcrumbs',
            component: () => import('@/views/base/Breadcrumbs.vue'),
          },
          {
            path: '/base/cards',
            name: 'Cards',
            component: () => import('@/views/base/Cards.vue'),
          },
          {
            path: '/base/carousels',
            name: 'Carousels',
            component: () => import('@/views/base/Carousels.vue'),
          },
          {
            path: '/base/collapses',
            name: 'Collapses',
            component: () => import('@/views/base/Collapses.vue'),
          },
          {
            path: '/base/list-groups',
            name: 'List Groups',
            component: () => import('@/views/base/ListGroups.vue'),
          },
          {
            path: '/base/navs',
            name: 'Navs',
            component: () => import('@/views/base/Navs.vue'),
          },
          {
            path: '/base/paginations',
            name: 'Paginations',
            component: () => import('@/views/base/Paginations.vue'),
          },
          {
            path: '/base/placeholders',
            name: 'Placeholders',
            component: () => import('@/views/base/Placeholders.vue'),
          },
          {
            path: '/base/popovers',
            name: 'Popovers',
            component: () => import('@/views/base/Popovers.vue'),
          },
          {
            path: '/base/progress',
            name: 'Progress',
            component: () => import('@/views/base/Progress.vue'),
          },
          {
            path: '/base/spinners',
            name: 'Spinners',
            component: () => import('@/views/base/Spinners.vue'),
          },
          {
            path: '/base/tables',
            name: 'Tables',
            component: () => import('@/views/base/Tables.vue'),
          },
          {
            path: '/base/tooltips',
            name: 'Tooltips',
            component: () => import('@/views/base/Tooltips.vue'),
          },
        ],
      },
      {
        path: '/buttons',
        name: 'Buttons',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/buttons/standard-buttons',
        children: [
          {
            path: '/buttons/standard-buttons',
            name: 'Buttons',
            component: () => import('@/views/buttons/Buttons.vue'),
          },
          {
            path: '/buttons/dropdowns',
            name: 'Dropdowns',
            component: () => import('@/views/buttons/Dropdowns.vue'),
          },
          {
            path: '/buttons/button-groups',
            name: 'Button Groups',
            component: () => import('@/views/buttons/ButtonGroups.vue'),
          },
        ],
      },
      {
        path: '/forms',
        name: 'Forms',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/forms/form-control',
        children: [
          {
            path: '/forms/form-control',
            name: 'Form Control',
            component: () => import('@/views/forms/FormControl.vue'),
          },
          {
            path: '/forms/select',
            name: 'Select',
            component: () => import('@/views/forms/Select.vue'),
          },
          {
            path: '/forms/checks-radios',
            name: 'Checks & Radios',
            component: () => import('@/views/forms/ChecksRadios.vue'),
          },
          {
            path: '/forms/range',
            name: 'Range',
            component: () => import('@/views/forms/Range.vue'),
          },
          {
            path: '/forms/input-group',
            name: 'Input Group',
            component: () => import('@/views/forms/InputGroup.vue'),
          },
          {
            path: '/forms/floating-labels',
            name: 'Floating Labels',
            component: () => import('@/views/forms/FloatingLabels.vue'),
          },
          {
            path: '/forms/layout',
            name: 'Layout',
            component: () => import('@/views/forms/Layout.vue'),
          },
          {
            path: '/forms/validation',
            name: 'Validation',
            component: () => import('@/views/forms/Validation.vue'),
          },
        ],
      },
      {
        path: '/charts',
        name: 'Charts',
        component: () => import('@/views/charts/Charts.vue'),
      },
      {
        path: '/icons',
        name: 'Icons',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/icons/coreui-icons',
        children: [
          {
            path: '/icons/coreui-icons',
            name: 'CoreUI Icons',
            component: () => import('@/views/icons/CoreUIIcons.vue'),
          },
          {
            path: '/icons/brands',
            name: 'Brands',
            component: () => import('@/views/icons/Brands.vue'),
          },
          {
            path: '/icons/flags',
            name: 'Flags',
            component: () => import('@/views/icons/Flags.vue'),
          },
        ],
      },
      {
        path: '/notifications',
        name: 'Notifications',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/notifications/alerts',
        children: [
          {
            path: '/notifications/alerts',
            name: 'Alerts',
            component: () => import('@/views/notifications/Alerts.vue'),
          },
          {
            path: '/notifications/badges',
            name: 'Badges',
            component: () => import('@/views/notifications/Badges.vue'),
          },
          {
            path: '/notifications/modals',
            name: 'Modals',
            component: () => import('@/views/notifications/Modals.vue'),
          },
        ],
      },
      {
        path: '/widgets',
        name: 'Widgets',
        component: () => import('@/views/widgets/Widgets.vue'),
      },
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register ori'),
      },
    ],
  },
]

const router = createRouter({
  // history: createWebHashHistory(process.env.BASE_URL),
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
