export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboardme',
    icon: 'cil-speedometer',
    badge: {
      color: 'warning',
      text: 'NEW',
    },
  },

  ////////////////////////////////////////////////////////
  {
    component: 'CNavTitle',
    name: 'MERCHANTS',
  },
  {
    component: 'CNavItem',
    name: 'Add Merchant',
    icon: 'cil-star',
    to: '/registermerchant',
  },
  {
    component: 'CNavItem',
    name: 'List Merchants',
    to: '/listmerchant',
    icon: 'cil-star',
  },
  ////////////////////////////////////////////////////////
  {
    component: 'CNavTitle',
    name: 'VOUCHERS',
  },
  {
    component: 'CNavItem',
    name: 'Add Voucher',
    icon: 'cilCreditCard',
    to: '/addvoucher',
  },
  {
    component: 'CNavItem',
    name: 'List Vouchers',
    icon: 'cilCreditCard',
    to: '/listvouchersadmin',
  },
  ////////////////////////////////////////////////////////
  {
    component: 'CNavTitle',
    name: 'BANNERS',
  },
  {
    component: 'CNavItem',
    name: 'Add Banner',
    icon: 'cil-puzzle',
    to: '/addbanner',
  },
  {
    component: 'CNavItem',
    name: 'List Banners',
    icon: 'cil-puzzle',
    to: '/listbannersadmin',
  },
  /////////////////////////////////////////////////////////
  {
    component: 'CNavTitle',
    name: 'VIDEOS',
  },
  {
    component: 'CNavItem',
    name: 'Add Video',
    icon: 'cilVideo',
    to: '/addvideo',
  },
  {
    component: 'CNavItem',
    name: 'List Videos',
    icon: 'cilVideo',
    to: '/listvideosadmin',
  },
  //////////////////////////////////////////////////////////
  {
    component: 'CNavTitle',
    name: 'REPORTS',
  },
  {
    component: 'CNavItem',
    name: 'Members Report',
    to: '/listmember',
    icon: 'cil-user',
  },
  {
    component: 'CNavItem',
    name: 'Vouchers Report',
    to: '/reportvouchers',
    icon: 'cil-notes',
  },
]
