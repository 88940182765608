<template>
  <div>
    <AppSidebar v-if="this.localS && this.sesS == 'admin'" />
    <div class="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppHeader v-if="this.localS && this.sesS == 'admin'" />
      <div
        class="body flex-grow-1"
        style="overflow-x: hidden; background-color: white"
      >
        <router-view />
        <!-- </CContainer> -->
      </div>
      <!-- <AppFooter /> -->
    </div>
  </div>
</template>
<script>
// import { CContainer } from '@coreui/vue'
// import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'

export default {
  data() {
    return {
      localS: localStorage.getItem('aingSaha'),
      sesS: sessionStorage.getItem('aingSaha'),
    }
  },

  name: 'DefaultLayout',
  components: {
    // AppFooter,
    AppHeader,
    AppSidebar,
    // CContainer,
  },
}
</script>
