<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar :src="avatar" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Account
      </CDropdownHeader>
      <CDropdownDivider />
      <CDropdownItem>
        <CIcon @click="logout()" icon="cil-lock-locked" />
        Logout
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import { getAuth, signOut } from 'firebase/auth'
import avatar from '@/assets/images/avatars/8.jpg'
export default {
  name: 'AppHeaderDropdownAccnt',
  setup() {
    return {
      avatar: avatar,
      itemsCount: 42,
    }
  },
  methods: {
    logout() {
      localStorage.removeItem('dataLoginUser')
      localStorage.removeItem('aingSaha')
      sessionStorage.removeItem('aingSaha')
      this.logoutGoogle()
    },
    async logoutGoogle() {
      const auth = getAuth()
      signOut(auth)
        .then(() => {
          window.location.href = '/loginadmin'
        })
        .catch((error) => {
          console.log(error)

          // An error happened.
        })
    },
  },
}
</script>
