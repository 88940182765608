import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//lazyload
import VueLazyload from 'vue-lazyload'

import 'material-icons/iconfont/material-icons.css'
import 'bootstrap-icons/font/bootstrap-icons.css'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsExample from '@/components/DocsExample'

import { initializeApp } from 'firebase/app'

import { getFirestore } from 'firebase/firestore'
const firebaseConfig = {
  apiKey: 'AIzaSyBDHOqj_c3ZjVxgHVVlPGxSoML4FBuyEjU',
  authDomain: 'myeatkaki-2023.firebaseapp.com',
  projectId: 'myeatkaki-2023',
  storageBucket: 'myeatkaki-2023.appspot.com',
  messagingSenderId: '350459217296',
  appId: '1:350459217296:web:e12ee9554e94b9f1309f15',
}

export const db = initializeApp(firebaseConfig)
export const dbfirebase = getFirestore(db)

const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsExample', DocsExample)
app.mount('#app')

//lazyload
app.use(VueLazyload, {
  preLoad: 1.3,
  error: 'dist/error.png',
  loading: 'dist/loading.gif',
  attempt: 1,
})
