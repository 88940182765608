<template>
  <router-view />
</template>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>

<script>
export default {
  beforeMount() {
    //buat hide url bar dgn cara di scroll ke atasin
    window.scrollTo(0, 1)
  },
}
</script>
